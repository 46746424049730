* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cabin, sans-serif;
}

:root {
  --transition-colors: color, background-color, border-color, color-mix, box-shadow, linear-gradient, outline-color;
  --transition-all: all;
  --transition03: .3s ease;
  --transition05: .5s ease;
}

:root[data-theme="light"] {
  --text: #171717;
  --text-static-light: #e8e8e8;
  --text-static-dark: #2c2c2c;
  --text-excitement: #fa3;
  --background: #f2f2f2;
  --card: #fff;
  --box: #f1f2f3;
  --success: #19a700;
  --option: #f6b3a2;
  --option-hover: #e59e8d;
  --warning: #a31300;
  --fail: #930700;
  --submit: #9ccea0;
  --submit-hover: #7fb884;
  --close: #2f3640;
  --close-hover: #3b3e4d;
  --disabled: #b0b8c4;
  --hint: #99edfd;
  --hint-hover: #7bd4e3;
  --reveal: #c7bff3;
  --reveal-hover: #b1a8e2;
  --accent: #273c75;
  --highlight: #487eb0;
  --theme-toggle: gold;
  --theme-accent: #ebeaeb;
}

:root[data-theme="dark"] {
  --text: #e1e1e1;
  --text-static-light: #e8e8e8;
  --text-static-dark: #2c2c2c;
  --text-excitement: #fc6;
  --background: #1e1e1e;
  --card: #191919;
  --box: #1e1e1e;
  --success: #0c5300;
  --option: #4b1414;
  --option-hover: #6a1a1a;
  --warning: #ff806f;
  --fail: #930700;
  --submit: #0c4e00;
  --submit-hover: #4da346;
  --close: #3b3e4d;
  --close-hover: #4c5060;
  --disabled: #7f8fa6;
  --hint: #023640;
  --hint-hover: #3a7981;
  --reveal: #2e2370;
  --reveal-hover: #7d73cc;
  --accent: #576fca;
  --highlight: #5a9fd8;
  --theme-toggle: #1e3a8a;
  --theme-accent: #151414;
}

footer {
  text-align: center;
  background-color: var(--card);
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  font-size: 1em;
  box-shadow: 0 8px 16px #0000001a;
}

.switch {
  width: 3.5em;
  height: 2.4rem;
  font-size: 17px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  cursor: pointer;
  background-color: var(--theme-toggle);
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0 .3em;
  transition: all .4s;
  display: flex;
  position: absolute;
  inset: 0;
}

.slider span {
  background-color: var(--theme-accent);
  border-radius: 8px;
  width: 1.4em;
  height: 1.4em;
  transition: all .4s;
  position: absolute;
  bottom: .4em;
  left: .3em;
  transform: rotate(270deg);
}

.slider:before, .slider:after {
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.4em;
  height: 1.4em;
}

.slider:before {
  content: "🌙";
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider:after {
  content: "☀️";
  justify-content: center;
  align-items: center;
  display: flex;
}

.switch input:checked + .slider span {
  transform: translateX(1.5em);
}

body {
  background-color: var(--background);
  color: #2f3640;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  min-height: 100vh;
  font-family: Helvetica Neue, Arial, sans-serif;
}

#mode-selector {
  flex-direction: column;
  gap: 10px;
  width: 100%;
  display: flex;
}

.mode-btn {
  cursor: pointer;
  background-color: var(--card);
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 1em;
}

.mode-btn.active {
  background-color: var(--option);
  color: var(--text);
}

#game-wrapper {
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
}

#main-game {
  flex-direction: column;
  width: 100%;
  display: flex;
}

#game-container {
  background-color: var(--card);
  text-align: center;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  padding: 20px;
  position: relative;
  box-shadow: 0 8px 16px #0000001a;
}

#scoreboard-container {
  background-color: var(--card);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0 8px 16px #0000001a;
}

#guess-history-container {
  background-color: var(--card);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 8px 16px #0000001a;
}

#guess-history-container h2 {
  color: var(--text);
  text-align: center;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0;
  font-size: 2em;
  display: flex;
}

h1 {
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  margin-bottom: 20px;
  font-size: 3.5em;
}

#clues {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.clue {
  background-color: var(--box);
  color: var(--highlight);
  text-align: center;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  font-weight: bold;
}

#guess-input {
  background-color: var(--background);
  border: 2px solid var(--box);
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 8px;
  outline: none;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  font-size: 1em;
}

#guess-input:focus {
  border-color: var(--accent);
}

.btn-submit {
  cursor: pointer;
  background-color: var(--submit);
  color: var(--text);
  transition: background-color .3s;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  font-size: 1em;
}

.btn-submit:hover {
  background-color: var(--submit-hover);
}

.btn-skip {
  cursor: pointer;
  background-color: var(--option);
  color: var(--text);
  transition: background-color .3s;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 8px;
  width: 50%;
  padding: 15px;
  font-size: 1em;
}

.btn-skip:hover {
  background-color: var(--option-hover);
}

.button-group {
  gap: 10px;
  margin-bottom: 15px;
  display: flex;
}

.btn {
  cursor: pointer;
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 8px;
  padding: 12px 10px;
  font-size: .9em;
}

.btn-hint {
  background-color: var(--hint);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  width: 50%;
}

.btn-hint:hover {
  background-color: var(--hint-hover);
}

.btn-category {
  background-color: var(--reveal);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  width: 50%;
}

.btn-category:hover {
  background-color: var(--reveal-hover);
}

#message {
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  margin-top: 10px;
  font-size: 1.1em;
}

#attempts {
  color: var(--warning);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  margin-top: 5px;
  font-size: 1.1em;
  font-weight: bold;
}

.btn:disabled, .btn-submit:disabled {
  background-color: var(--disabled);
  cursor: not-allowed;
}

#hint, #category {
  color: var(--highlight);
  margin-top: 15px;
  font-style: italic;
}

#guess-history {
  width: 100%;
  margin-top: 20px;
}

.guess-tile {
  background-color: var(--box);
  color: var(--text-static-light);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  opacity: 0;
  border-radius: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  font-weight: bold;
  animation: .5s forwards fadeIn;
  display: flex;
  position: relative;
}

.guess-tile.correct {
  background-color: var(--success);
  opacity: 1;
  transform: scale(1.02);
}

.guess-tile.incorrect {
  background-color: var(--fail);
  opacity: 1;
}

#answer-reveal {
  text-align: center;
  color: #44bd32;
  margin-top: 20px;
  font-size: 1.2em;
}

.guess-tile .icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.guess-text {
  word-wrap: break-word;
  word-break: break-word;
  flex: 1;
}

#scoreboard {
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1em;
  display: flex;
}

#scoreboard > div {
  background-color: var(--box);
  text-align: center;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
}

#modal-overlay {
  z-index: 1000;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  background: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: .5s forwards slideIn;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#modal {
  background-color: var(--card);
  text-align: center;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  padding: 30px;
  box-shadow: 0 5px 15px #0000004d;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#modal h2 {
  color: var(--text-excitement);
  margin-bottom: 20px;
  font-size: 4em;
  animation: 1s infinite alternate glow;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px var(--hint);
  }

  to {
    text-shadow: 0 0 10px var(--hint);
  }
}

#modal p {
  margin-bottom: 15px;
  font-size: 1.6em;
}

#modal .btn-close {
  background-color: var(--close);
  color: var(--text-static-light);
  cursor: pointer;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  padding: 12px 25px;
  font-size: 1em;
}

#modal .btn-close:hover {
  background-color: var(--close-hover);
}

#modal-word {
  font-weight: bold;
}

#modal span {
  color: var(--highlight);
}

#modal-hints-used, #modal-score-gained, #modal-streak, #modal-word, #modal-highscore, #modal-message {
  color: var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  font-weight: bold;
}

#instruction-modal-overlay {
  z-index: 1000;
  transition: var(--transition03);
  background: #000c;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

#instruction-modal {
  background-color: var(--card);
  color: var(--text);
  text-align: left;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  width: 100%;
  max-width: 700px;
  padding: 50px 25px 25px;
  font-size: 1.2em;
  line-height: 1.6;
  animation: .5s forwards slideIn;
  overflow: hidden auto;
  box-shadow: 0 8px 20px #0000004d;
}

#instruction-modal-wrapper {
  max-height: 80vh;
  padding: 20px;
  display: flex;
  position: relative;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

#instruction-modal h2 {
  text-align: center;
  color: var(--accent);
  margin-bottom: 20px;
  font-size: 2.5em;
}

#instruction-modal h3 {
  color: var(--heading);
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 1.6em;
}

#instruction-modal p, #instruction-modal ul {
  margin-bottom: 10px;
}

#instruction-modal ul {
  list-style: inside disc;
  margin-left: 20px;
}

#instruction-modal ul li {
  margin-bottom: 5px;
}

#instruction-modal strong {
  color: var(--highlight);
}

#instruction-close {
  background-color: var(--close);
  color: var(--text-static-light);
  cursor: pointer;
  transition: var(--transition03);
  z-index: 100;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: .75em;
  animation: .5s forwards slideIn;
  position: absolute;
  top: 5px;
  right: 10px;
}

#instruction-modal .btn-close:hover {
  background-color: var(--close-hover);
}

.btn-instructions {
  background-color: var(--hint);
  color: var(--text);
  cursor: pointer;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border: none;
  border-radius: 10px;
  padding: 10.5px 20px;
  font-size: .75rem;
}

.btn-instructions:hover {
  background-color: var(--hint-hover);
}

.accordion-toggle {
  background-color: var(--card);
  color: var(--text);
  text-align: left;
  cursor: pointer;
  border: none;
  display: block;
}

.accordion-content {
  opacity: 0;
  max-height: 0;
  transition: max-height .3s ease-out, opacity .3s ease-out;
  display: block;
  overflow: hidden;
}

.accordion-content.expanded {
  animation: .3s forwards expand;
}

.accordion-content.collapsed {
  animation: .3s forwards collapse;
}

.arrow {
  border: solid var(--text);
  transition: var(--transition03);
  transition-property: var(--transition-all);
  border-width: 0 6px 6px 0;
  padding: 6px;
  display: inline-block;
}

.down {
  transform: rotate(45deg);
}

#toolbar {
  background-color: var(--card);
  z-index: 100;
  transition: var(--transition03);
  transition-property: var(--transition-colors);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  display: flex;
  position: fixed;
  bottom: 10px;
  box-shadow: 0 8px 16px #0000001a;
}

@keyframes expand {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 500px;
  }
}

@keyframes collapse {
  from {
    opacity: 1;
    max-height: 500px;
  }

  to {
    opacity: 0;
    max-height: 0;
  }
}

#dropdown-container {
  width: 100%;
  display: flex;
  position: relative;
}

.dropdown-toggle {
  color: var(--text);
  text-align: left;
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  font-size: 2.5em;
  font-weight: bold;
}

.dropdown-content {
  opacity: 0;
  width: 100%;
  max-height: 0;
  transition: max-height .3s ease-out, opacity .3s ease-out;
  overflow: hidden;
}

.dropdown-content.expanded {
  animation: .3s forwards expand;
}

.dropdown-content.collapsed {
  animation: .3s forwards collapse;
}

#confetti {
  position: absolute;
}

@media (width >= 768px) {
  #game-wrapper {
    flex-flow: row-reverse wrap;
    max-width: 900px;
  }

  #main-game {
    width: 60%;
  }

  #guess-history-container {
    width: calc(40% - 20px);
  }

  #clues {
    flex-direction: row;
    justify-content: space-around;
  }

  #mode-selector {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .mode-btn {
    flex: 1;
    margin-bottom: 0;
  }

  #scoreboard > div {
    width: calc(50% - 10px);
  }

  .btn {
    width: 50%;
  }

  #instruction-modal {
    font-size: 1.2em;
  }

  .accordion-toggle {
    display: none;
  }

  .accordion-content {
    opacity: 1;
    max-height: unset;
    display: block;
  }

  .dropdown-toggle {
    display: none;
  }

  .dropdown-content {
    opacity: 1;
    max-height: unset;
    flex-wrap: wrap;
    gap: 20px;
    display: flex;
  }

  #toolbar {
    position: unset;
    width: 100%;
  }
}
/*# sourceMappingURL=index.01172ee7.css.map */
